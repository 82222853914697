@import url(../node_modules/bootstrap/dist/css/bootstrap.min.css);
@import url(../node_modules//bootstrap-icons/font/bootstrap-icons.css);

.navbar {
  background-color: var(--bg-color);
}

.navbar .navbar-brand {
  font: 800 1.8rem var(--heading-font);
}

.navbar-nav .nav-link {
  color: var(--tertiary-color);
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover {
  color: var(--primary-color);
}

.navbar-nav .nav-link::after {
  content: "";
  width: 80%;
  height: 2px;
  background-color: var(--primary-color);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform .5s ease-out;
}

.navbar-nav .nav-link.active::after,
.navbar-nav .nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

#alert {
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

#alert .alert {
  display: flex;
  color: var(--bg-color);
}

#alert .success {
  background: var(--gradient);
}

#alert .error {
  background: var(--primary-color);
}

main {
  margin-top: 65px;
}

#hero {
  min-height: calc(100vh - 65px);
  display: flex;
  align-items: center;
  background: url(./assets/img/hero/hero-bg.png) left top/cover no-repeat;
}

#hero .intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#hero .intro .right-dash::after {
  width: 65%;
  margin-left: 1rem;
}

#hero .intro h1,
#hero .intro span.Typewriter__cursor {
  font: 800 calc(1.475rem + 2vw) var(--heading-font);
  display: inline-block;
  margin-bottom: 0;
}

#hero .action a:nth-child(1) {
  margin-right: 2rem;
}

#hero .action a:nth-child(2) {
  font-weight: 600;
  transition: color .5s ease;
}

#hero .action a .file-icon {
  width: 35px;
  height: 35px;
  line-height: 35px;
  padding: .5rem;
  border-radius: 50%;
  background-color: var(--tertiary-color);
  box-shadow: 0 0 0 .5rem var(--baby-blue-color);
  transition: box-shadow .5s ease;
}

#hero .action a:nth-child(2):hover .file-icon {
  box-shadow: 0 0 0 .5rem var(--baby-blue-hover);
}

#hero .action a svg {
  color: aliceblue;
}

#hero .img img {
  width: 100%;
  object-fit: cover;
}

#stack .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border-radius: .5rem;
}

#stack .wrapper.mongo-db {
  background-color: rgba(0, 237, 100, .1);
}

#stack .wrapper.mongo-db svg {
  color: #13aa52;
}

#stack .wrapper .desc {
  margin-left: 2.5rem;
}

#stack .wrapper .desc h4 {
  font-weight: 800;
}

#stack .wrapper .desc p {
  margin-bottom: 0;
}

#stack .wrapper.express {
  background-color: #eee;
}

#stack .wrapper.express svg {
  color: #a3a3a3;
}

#stack .wrapper.react {
  background-color: rgba(97, 218, 251, .2);
}

#stack .wrapper.react svg {
  color: #61dafb;
}

#stack .wrapper.node {
  background-color: rgba(108, 194, 74, .2);
}

#stack .wrapper.node svg {
  color: #44883e;
}

#about,
#contact {
  background: var(--bg-color);
}

#about img {
  width: 100%;
}

#about b {
  font-weight: 600;
}

#projects {
  padding: 4.5rem 0 3rem 0;
}

#projects .filters .btn-filter {
  color: var(--tertiary-color);
  margin: .5rem;
  border: 3px solid var(--baby-blue-color);
  transition: border .5s ease;
}

#projects .filters .btn-filter.active,
#projects .filters .btn-filter:hover {
  border: 3px solid var(--blue-purple-color);
}

#projects .project .wrapper {
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #e1e1e1;
  box-shadow: var(--box-shadow);
  transition: box-shadow .3s ease;
}

#projects .project .wrapper:hover {
  box-shadow: var(--box-shadow-hover);
}

#projects .project a > img {
  width: 100%;
}

#projects .project a > img {
  margin-bottom: .45rem;
}

#projects .project .details .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#projects .project .details h5 {
  margin: 0;
}

#projects .project .details svg {
  color: #171515;
  font-size: 1.8em;
}

#projects .project .details hr {
  margin: .5rem 0 1rem 0;
}

#projects .project .details .logos {
  display: flex;
  justify-content: space-between;
}

#projects .project .details .logos img {
  width: 6%;
  height: 6%;
}

#contact .left-dash::before,
#contact .right-dash::after {
  width: 20%;
  margin: 0 1rem;
}

#contact form label {
  font-weight: 600;
  text-transform: capitalize;
}

#contact .contacts .phone span:nth-child(3) {
  cursor: pointer;
}

#contact .contacts .email span {
  color: var(--secondary-color);
  cursor: pointer;
}

#contact .contacts .socials h4 {
  margin-bottom: 1rem;
}

#contact .contacts .socials a {
  margin-right: 1.5rem;
}

#contact .contacts .socials a:nth-child(2) {
  color: #0077b5;
}

#contact .contacts .socials a:nth-child(3) {
  color: #171515;
}

#contact .contacts .socials a:nth-child(4) {
  color: #1d9bf0;
}

#contact .contacts .socials a:nth-child(5) * {
  fill: url(#ig);
}

footer {
  background-color: var(--bg-color-dark);
  box-shadow: var(--box-shadow-hover);
}

@media only screen and (max-width: 1199px) {
  #hero .intro .right-dash::after {
    width: 30%;
    transform: translateY(-5px);
  }
}

@media only screen and (max-width: 991px) {
  .navbar .navbar-toggler {
    border: 2px solid var(--blue-purple-color);
    transition: box-shadow .5s ease;
  }
  .navbar .navbar-toggler:hover,
  .navbar .navbar-toggler:focus {
    box-shadow: 0 0 0 .15rem var(--baby-blue-color);
  }
  .navbar .navbar-toggler svg {
    color: var(--blue-purple-color);
  }
  .navbar-nav .nav-item {
    margin: .3rem 0;
  }
  .navbar-nav .nav-link {
    display: inline-block;
  }
  main {
    margin-top: 59px;
  }
  #hero {
    min-height: calc(100vh - 59px);
  }
  #contact .contacts {
    margin-top: 75px;
  }
}

@media only screen and (max-width: 374px) {
  main button, .link-btn {
    padding: .8rem 1.5rem !important;
  }
}

@media only screen and (max-width: 359px) {
  #hero .action a:nth-child(1) {
    margin: 0 0 1.8rem 0;
  }
  #hero .action a:nth-child(2) {
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  #alert {
    min-width: 90%;
  }
}

@media only screen and (min-width: 768px) {
  #hero .intro p.lead {
    max-width: 80%;
    align-self: center;
  }
}

@media only screen and (min-width: 992px) {
  .navbar-nav .nav-item {
    padding: .5em;
  }
  .navbar-nav .nav-link {
    padding: 0 !important;
  }
  #contact .contacts {
    margin-top: 150px;
  }
}

@media only screen and (min-width: 1200px) {
  #hero {
    height: calc(100vh - 65px);
  }
  #hero .intro p.lead {
    max-width: 100%;
    align-self: auto;
  }
  #hero .img img {
    height: 45vw;
  }
}

@media only screen and (min-width: 1600px) {
  #hero .img img {
    height: 40vw;
  }
}

@media only screen and (min-width: 1800px) {
  #hero .intro h1,
  #hero .intro span.Typewriter__cursor {
    font-size: 3.5rem;
  }
  #hero .img img {
    height: 35vw;
  }
}

@media only screen and (min-width: 2000px) {
  #hero .img img {
    height: 28vw;
  }
}