@font-face {
    font-family: 'Alegreya';
    font-weight: 700;
    src: url(../assets/fonts/Alegreya/static/Alegreya-Bold.ttf);
}

@font-face {
    font-family: 'Alegreya';
    font-weight: 800;
    src: url(../assets//fonts/Alegreya/static/Alegreya-ExtraBold.ttf);
}

@font-face {
    font-family: 'Telex';
    src: url(../assets/fonts/Telex/Telex-Regular.ttf);
}