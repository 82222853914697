@import url(./stylesheets/Fonts.css);

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --body-font: 'Telex';
  --heading-font: 'Alegreya';
  --primary-color: #e963db;
  --secondary-color: #3076e0;
  --tertiary-color: #4b55b4;
  --blue-purple-color: #b4a0eb;
  --baby-blue-color: #b5d1f2;
  --baby-blue-hover: #a6c7ed;
  --bg-color: ghostwhite;
  --bg-color-dark: #e3e3f6;
  --box-shadow: 0 10px 30px rgba(57, 56, 61, .2);
  --box-shadow-hover: 0 10px 30px rgba(57, 56, 61, .3);
  --gradient: linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%);
}

body {
  margin: 0;
  font-family: var(--body-font), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font), serif;
}

a {
  display: inline-block;
  color: var(--secondary-color);
  text-decoration: none;
}

a:hover {
  color: var(--tertiary-color);
}

button, .link-btn {
  font-weight: 600;
  padding: 1rem 2rem;
  border-radius: 5px;
  border: none;
  outline: none;
  background: none;
}

button:disabled {
  opacity: .5;
  pointer-events: none;
}

.btn-gradient {
  color: aliceblue;
  position: relative;
  background: var(--gradient);
  box-shadow: var(--box-shadow);
  transition: all .5s ease;
  z-index: 1;
}

.btn-gradient::before {
  content: "";
  border-radius: 2px;
  background: transparent;
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  transition: background .5s ease;
  z-index: -1;
}

.btn-gradient:hover {
  color: var(--tertiary-color);
  transform: translateY(-2px);
}

.btn-gradient:hover::before {
  background: #fff;
}

.left-dash::before,
.right-dash::after {
  content: "";
  height: 2px;
  display: inline-block;
  background: #000;
  transform: translateY(-6px);
}

.py-72 {
  padding: 4.5rem 0;
}

.icon {
  width: 2em;
  height: 2em;
}

@media only screen and (max-width: 991px) {
  html {
    font-size: 90%;
  }
}